<template>
  <!-- valuation-h5 - 测评管理 - 订单中心 - 运营 & 机构 --> <!-- v-if="userJson.roleId == '1' || userJson.roleId == '-1'" -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单中心</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">测评订单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px;">
            <div title="测评问卷名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem;">测评问卷名称:</span>
              <el-input v-model="searchForm.paperName" type="text" clearable size="small" placeholder="请输入测评问卷名称" />
            </div>
            <div title="渠道名称" class="searchboxItem ci-full">
              <span class="itemLabel">渠道名称:</span>
              <el-input v-model="searchForm.channelName" type="text" clearable size="small" placeholder="请输入渠道名称" />
            </div>
            <div title="支付状态" class="searchboxItem ci-full">
              <span class="itemLabel">支付状态:</span>
              <el-select clearable size="small" v-model="searchForm.orderStatus" placeholder="请选择支付状态">
                <el-option v-for="item in pageDatas.orderStatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="下单时间" class="searchboxItem ci-full">
              <span class="itemLabel">下单时间:</span>
              <el-date-picker clearable size="small" v-model="searchForm.placeOrderTime" type="daterange"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div title="机构名称" class="searchboxItem ci-full" v-if="userJson.roleId == '1' || userJson.roleId == '-1'">
              <span class="itemLabel">机构名称:</span>
              <el-input v-model="searchForm.compName" type="text" clearable size="small" placeholder="请输入机构名称" />
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" round @click="financialExport">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" stripe :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="60px" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" min-width="100px" />
              <el-table-column label="价格" align="left" show-overflow-tooltip prop="productPrice" min-width="60px" />
              <el-table-column label="优惠金额" align="left" show-overflow-tooltip prop="districtMoney" min-width="60px" />
              <el-table-column label="实付金额" align="left" show-overflow-tooltip prop="paymentMoney" min-width="60px" />
              <el-table-column label="支付状态" align="center" show-overflow-tooltip prop="orderStatus" min-width="100px">
                <template slot-scope="scope">
                  <span v-if="scope.row.orderStatus == 1">未完成</span>
                  <span v-if="scope.row.orderStatus == 2">已完成</span>
                  <span v-if="scope.row.orderStatus == 0">全部</span>
                </template>
              </el-table-column>
              <el-table-column label="订单号" align="left" show-overflow-tooltip prop="orderSn" min-width="140px" />
              <el-table-column label="测评名称" align="left" show-overflow-tooltip prop="paperName" min-width="150px" />
              <el-table-column label="机构名称" align="left" show-overflow-tooltip prop="compName" min-width="150px" v-if="userJson.roleId == '1' || userJson.roleId == '-1'" />
              <el-table-column label="渠道" align="left" show-overflow-tooltip prop="channelName" min-width="150px" />
              <el-table-column label="下单时间" align="center" show-overflow-tooltip prop="createTime" width="150px">
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
let editor = {};
export default {
  name: "evaluationManagement_orderList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 当前页面数据
      pageDatas: {
        orderStatusList: [
          {
            value: '0',
            label: '全部'
          },
          {
            value: '1',
            label: '未完成'
          },
          {
            value: '2',
            label: '已完成'
          }
        ], // 支付状态 - 字典数据
      },
      // 检索数据
      searchForm: {
        paperName: "", // 测评问卷名称
        channelName: "", // 渠道名称
        orderStatus: "0", // 支付状态
        placeOrderTime: "", // 下单时间
        compName: "", // 机构名称
      },
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser"
    }),
  },
  mounted() { },
  created() { },
  methods: {
    // 获取字典
    getDictionary() {
      // 审核状态
      let auditStatus = this.$setDictionary("PAPER_AUDIT_STATE", "list");
      for (const key in auditStatus) {
        this.pageDatas.auditStatusList.push({
          value: key,
          label: auditStatus[key],
        });
      }
    },
    // 获取数据
    getData(pageNum = 1) {
      let url = "";
      if (this.userJson.roleId == '1' || this.userJson.roleId == '-1') {
        url = '/biz/hr/evaluate/order/pageListRun'
      }
      if (this.userJson.roleId == '3') {
        url = '/biz/hr/evaluate/order/pageList'
      }
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchForm.paperName) {
        params.paperName = this.searchForm.paperName
      }
      if (this.searchForm.channelName) {
        params.channelName = this.searchForm.channelName
      }
      if (this.searchForm.orderStatus) {
        params.orderStatus = this.searchForm.orderStatus
      }
      if (this.searchForm.placeOrderTime) {
        params.createTime = this.searchForm.placeOrderTime[0];
        params.endTime = this.searchForm.placeOrderTime[1];
      }
      if (this.searchForm.compName) {
        params.compName = this.searchForm.compName
      }
      this.doFetch({
        url,
        params,
        pageNum,
      }, true, 6);
    },
    // 导出
    financialExport() {
      let url = "";
      if (this.userJson.roleId == '1' || this.userJson.roleId == '-1') {
        url = '/biz/hr/evaluate/order/exportRun'
      }
      if (this.userJson.roleId == '3') {
        url = '/biz/hr/evaluate/order/export'
      }
      let params = {};
      if (this.searchForm.paperName) {
        params.paperName = this.searchForm.paperName
      }
      if (this.searchForm.channelName) {
        params.channelName = this.searchForm.channelName
      }
      if (this.searchForm.orderStatus) {
        params.orderStatus = this.searchForm.orderStatus
      }
      if (this.searchForm.placeOrderTime) {
        params.createTime = this.searchForm.placeOrderTime[0];
        params.endTime = this.searchForm.placeOrderTime[1];
      }
      if (this.searchForm.compName) {
        params.compName = this.searchForm.compName
      }
      this.$post(url, params, 3000, true, 6).then((res) => {
        const arr = [...res.data];
        for (let item of arr) {
          if (!this.downloadItems.includes(item.taskId)) {
            this.$store.dispatch("pushDownloadItems", item.taskId);
          } else {
            this.$message.warning(`[${item.fileName}]已经申请下载`);
          }
        }
      }).catch((e) => {
        console.log(e);
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped></style>
